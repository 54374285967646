.header {
	position: relative;
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
	background-color: #FFF;
	color: black;
    padding: var(--page-padding);

    &__button {
        padding-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        width: fit-content;

        a {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        
        img {
            opacity: 1;
            border-radius: 100%;
            transform: rotate(90deg);
        }
        
        img:hover {
            opacity: 0.8;
        }
    }
}

.header h1,
.header p {
	z-index: 2;
	text-align: center;
}

.header p {
	font-family: "Poppins", sans-serif;
	font-size: 1.25rem;
	line-height: normal;
    width: 70vw;
}

.header h1 {
	font-family: Gilroy-Bold, sans-serif;
	font-size: var(--header-title);
}

.header__img {
	height: 100%;
	width: 100%;
	object-fit: cover;
}

.header__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 74vw;
}

.cta {
	margin-top: 15px;
	color: rgb(255, 255, 255);
	font-family: "Space Grotesk", sans-serif;
	font-size: 16px;
	font-weight: 700;
	cursor: pointer;
	background: linear-gradient(40.44deg, #278372 -15.44%, #9ed1c7 176.19%);
	width: 180px;
	height: 50px;
	border-radius: 50px;
}

.header a {
	z-index: 3;
}

/* ================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {

	.header h1,
	.header p {
		text-align: left;
	}
 
}

@media screen and (max-width: 600px) {
    .header h1 {
        font-size: 3.2rem;
    }

    .header__content {
        width: 100%;
        align-items: flex-start;

        p {
            width: 100%;
        }
    }

    
}

@media screen and (max-width: 500px) {
    .header h1 {
        font-size: 2.7rem;
        line-height: 48px
    }

    .header {
        height: 80vh;
        padding: 0 20px;

        p {
            font-size: 16px;
        }

        &__button {
            justify-content: flex-start;
            padding-top: 0px;
    
            img {
                opacity: 1;
                border-radius: 100%;
                transform: rotate(0);
            }
        }
    }

    .header__content {
        margin-top: 12vh;
        gap: 15px;
        width: 100%;
        align-items: flex-start;

        p {
            width: 100%;
        }
    }
}