@use './../../index.scss' as base;

.services {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    padding: 80px 5vw;
    background-color: #000000;
    // background-color: #181C1F;

    h2 {
        font-size: 56px;
        color: white;
    }

}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .services {
        padding: 80px 20px;
    }
}