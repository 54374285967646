.service {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    width: 100%;
    font-family: "Poppins", sans-serif;
    padding: 40px 0;
    cursor: pointer;
    gap: 15px;

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        img {
            background-color: transparent;
            color: white;
            height: 14px;
            width: fit-content;
        }
    }

    &__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: rgb(255, 255, 255);
        font-size: 24px;
        font-weight: 500;
        text-align: left;
        cursor: pointer;
        width: 100%;
    }

    &__explanation {
        display: flex;
        flex-direction: column;

        &__description {
            color: white;
            font-weight: 300;
            text-align: left;
        }
    }

    img {
        height: 35px;
        width: auto;
        background-color: white;
        border-radius: 100%;
    }
}

button {
    background-color: transparent;
}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .service {
        padding: 35px 0;
        
        &__explanation {
            font-size: 16px;
        }
    }
}