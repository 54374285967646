@use './../../index.scss' as base;

.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    width: 100%;
    color: white;
    background-color: #940000;
    padding: 40px 5vw;
    gap: 40px;

    &__rights {
        width: 100%;
        padding-bottom: 15px;

        p {
            text-align: left;
        }
    }

    &__contact {
        display: flex;
        flex-direction: row;
        gap: 150px;
    }

    .info, .social-media {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .info__options, .social-media__options {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    h4 {
        font-size: 16px;
        font-weight: bolder;
    }

    p {
        font-size: 14px;
        font-weight: normal;
    }
}




/* ================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px) {

}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
   .footer {
    padding: 40px 20px;

    &__contact {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
   }
}