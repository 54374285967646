.navbar
{
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid rgba(122, 122, 122, 0.300);
  padding: var(--page-padding);
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  z-index: 5;
}

.navbar a {
    color: rgb(73, 73, 73);
}

.navbar a:hover {
    color: rgb(255, 0, 0);
}

.navbar__logo
{
  flex: 1;
}

.navbar__menu
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 70%;
}

menu li
{
  transition: var(--transition);
}


/* ================== MEDIA QUERIES (MEDIUM DEVICES) ================== */
@media screen and (max-width: 1024px)
{
  .navbar__menu
  {
    flex: 2;
  }
}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px)
{
  .navbar__menu
  {
    display: none;
    align-items: center;
    background-color: chartreuse;
  }

  .navbar {
    height: 10vh;
    border-bottom: none;
  }

  /* .navbar__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
  } */
}