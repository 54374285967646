@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
    font-family: Gilroy-Bold;
    src: url("./fonts/Gilroy-Bold.ttf");
}

$font-size-l: 56px;
$font-size-m: 40px;
$line-height-m: 43px;
$page-padding: 0 10vw;

* {
    margin: 0;
    padding: 0;
    outline: none;
    border: none;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --color-bg: white;
    --color-primary: red;

    --transition: all 400ms ease;

    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
    --border-radius: 0 0 30px 30px;

    --font-super: 80px;
    --font-big: 56px;
    --header-title: 80px;
    --page-padding: 0 5vw;
}

html {
    scroll-behavior: smooth;
    font-size: 16px;
}

body {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
    text-align: left;
}

a {
    color: black;
    transition: var(--transition);
}

a:hover {
    color: red;
}

p {
    text-align: justify;
    hyphens: none;
    font-size: 18px;
    line-height: 1.3;
}

h2 {
    font-size: 30px;
    color: rgb(27, 27, 27);
}

h3 {
    margin-bottom: 20px;
}

.btn {
    width: max-content;
    display: none;
    color: white;
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: var(--transition);
    background-color: black;
}

.btn:hover {
    background: rgb(139, 139, 139);
    color: var(--color-bg);
    border-color: transparent;
}

.icon {
    height: 30px;
    font-size: 30px;
    margin-bottom: 10px;
}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .h2 {
        text-align: center;
    }
}