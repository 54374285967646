@use './../../index.scss' as base;

.benefits {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
    padding: base.$page-padding;
    // background-color: #F4F5F6;
    color: black;
    width: 100%;

    h5 {
        font-size: 16px;
        text-align: center;
        width: 100%;
        color: rgb(122, 122, 122);
        font-weight: 500;
    }

    h2 {
        text-align: center;
        line-height: 52px;
        font-size: 56px;
        width: 100%;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-top: 100px;
        width: fit-content;
        margin-bottom: 20px;
    }

    &__main {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 70px;
        width: fit-content;
        padding-bottom: 110px;

        > div {
            display: flex;
            flex-direction: row;
            gap: 70px;
        }

        img {
            position: absolute;
            height: 90%;
            width: fit-content;
            right: 50%;
        }

        h3 {
            font-size: 24px;
            line-height: 25px;
            font-weight: 500;
        }

        

        .benefit {
            width: 550px;
            color: black;
            border-left: solid #DDDEE3 2px;
            padding-left: 16px;

            p {
                text-align: left;
                font-size: 16px;
                line-height: 24px;
            }
        }

        .benefit:hover {
            border-left: solid #000000 4px;
        }
    }
}

/* ================== MEDIA QUERIES (SMALL DEVICES) ================== */
@media screen and (max-width: 600px) {
    .benefits {
        padding: 0 20px;

        h5 {
            text-align: center;
            width: 100%;
        }

        h2 {
            font-size: 32px;
            line-height: 36px;
            text-align: center;
        }

        &__main {
            > div {
                display: flex;
                flex-direction: column;
            }
        }

        .benefit {
            width: 80vw;
        }
    }
}